exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-history-js": () => import("./../../../src/pages/about/history.js" /* webpackChunkName: "component---src-pages-about-history-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publishing-books-js": () => import("./../../../src/pages/publishing/books.js" /* webpackChunkName: "component---src-pages-publishing-books-js" */),
  "component---src-pages-publishing-portfolios-js": () => import("./../../../src/pages/publishing/portfolios.js" /* webpackChunkName: "component---src-pages-publishing-portfolios-js" */),
  "component---src-pages-publishing-prints-js": () => import("./../../../src/pages/publishing/prints.js" /* webpackChunkName: "component---src-pages-publishing-prints-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-publish-template-js": () => import("./../../../src/templates/PublishTemplate.js" /* webpackChunkName: "component---src-templates-publish-template-js" */)
}

